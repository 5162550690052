<template>
  <div class="container-fluid">
    <div class="row" v-if="loading">
      <div class="col-12">
        <octo-loader/>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-lg-3 col-md-6" v-for="(card, index) in statsCards" :key="`card-${index}`">
        <stats-card
          class="shadow"
          :title="card.title"
          :sub-title="card.subTitle"
          :type="card.type"
          :icon="card.icon"
        >
          <div slot="footer" v-html="card.footer"></div>
        </stats-card>
      </div>
    </div>
  </div>

</template>

<script>
import StatsCard from "@/components/Cards/StatsCard";
import {endpoints} from "@/routes/endpoints";
import OctoLoader from "@/components/octo-loader/OctoLoader";
export default {
  name: "DashboardDealCards",
  components: {OctoLoader, StatsCard},
  data() {
    return {
      data: null,
      loading: false
    }
  },
  props: {
    datePickerValue: {
      type: Array,
      default: () => [null, null]
    },
  },
  watch: {
    datePickerValue: function (val) {
      this.getData(val)
    }
  },
  computed: {
    statsCards() {
      return [
        {
          title: String(this.data?.inDeals),
          subTitle: 'Deal',
          type: 'primary',
          icon: 'download',
          footer: 'Opportunità entrate'
        },
        {
          title: String(this.data?.inProgressDeals),
          subTitle: 'Deal',
          type: 'warning',
          icon: 'hourglass',
          footer: 'Opportunità in lavorazione'
        },
        {
          title: String(this.data?.outDeals),
          subTitle: 'Deal',
          type: 'success',
          icon: 'upload',
          footer: 'Opportunità uscite'
        },
        {
          title: String(this.data?.averageInProgressDays),
          subTitle: 'Days',
          type: 'info',
          icon: 'clock',
          footer: 'Tempo medio lavorazione'
        }
      ];
    }
  },
  methods: {
    getData: function (val) {
      this.loading = true;
      if (!val.hasOwnProperty(0) || !val.hasOwnProperty(1)) {
        return;
      }

      const queryParams = new URLSearchParams({
        'dateFrom': this.$moment(this.datePickerValue?.[0]).format('YYYY-MM-DD'),
        'dateTo': this.$moment(this.datePickerValue?.[1]).format('YYYY-MM-DD')
      });

      this.loading = true;
      this.data = [];

      this.$api.get(endpoints.DASHBOARD_DEALS_STATS + '?' + queryParams.toString())
        .then((resp) => {
          this.data = resp?.data || [];
        })
        .catch(() => {

        })
      .finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
