<template>
  <div class="row">
    <div class="col-12">
      <octo-chart-container title="Dashboard Opportunità" v-slot="{datePickerValue}">
        <dashboard-deal-cards :date-picker-value="datePickerValue"/>
        <h5 class="text-uppercase text-muted">{{ $t('deal.dash.deal_last_deal_date_vs_statuses') }}</h5>
        <dashboard-deal-status-table
          class="mb-4"
          :date-picker-value="datePickerValue"
          :endpoint="endpoints.DASHBOARD_DEALS_STATUS_BY_LAST_DEAL_DATE"
        />
        <h5 class="text-uppercase text-muted">{{$t('deal.dash.deal_date_out_vs_statuses')}}</h5>
        <dashboard-deal-with-date-out-table :date-picker-value="datePickerValue"/>
      </octo-chart-container>
    </div>
    <div class="col-12 col-md-6">
      <base-chart
        :title="$t('chart.deal_last_six_months_contract_amounts')"
        :height="350"
        :endpoint="endpoints.CHARTS.dealLastSixMonthsContractAmounts"
      />
    </div>
    <div class="col-12 col-md-6">
      <base-chart
        :title="$t('chart.deal_promoted_in_last_six_months')"
        :height="350"
        :endpoint="endpoints.CHARTS.dealPromotedInLastSixMonths"
      />
    </div>
  </div>
</template>

<script>
import OctoChartContainer from "@/components/octo-chart-container/OctoChartContainer";
import DashboardDealStatusTable from "@/pages/Deals/components/DashboardDealStatusTable";
import DashboardDealCards from "@/pages/Deals/components/DashboardDealCards";
import BaseChart from "@/components/Charts/BaseChart";
import {endpoints} from "@/routes/endpoints";
import DashboardDealWithDateOutTable from "./components/DashboardDealWithDateOutTable";

export default {
  name: "DashboardView",
  components: {
    DashboardDealWithDateOutTable,
    BaseChart,
    DashboardDealCards,
    DashboardDealStatusTable,
    OctoChartContainer
  },
  data() {
    return {
      endpoints: endpoints
    }
  }
}
</script>

<style scoped>

</style>
